import { CONSTRUCTOR } from '..';
import * as Fragments from './fragments';

export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getCampaign',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...CampaignFields}',
    fragments: [Fragments.campaign],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCampaigns',
    args: [
      { name: 'filters', type: '[Filter!]' },
      { name: 'sorts', type: '[Sort!]' },
      { name: 'limit', type: 'Int' },
      { name: 'per_query', type: 'Int' },
      { name: 'pit_id', type: 'String' },
      { name: 'search_after', type: 'String' },
      { name: 'list_back', type: 'Boolean' },
      { name: 'all', type: 'Boolean' },
      { name: 'page', type: 'Int' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'per_query', value: 'per_query' },
      { name: 'pit_id', value: 'pit_id' },
      { name: 'search_after', value: 'search_after' },
      { name: 'list_back', value: 'list_back' },
      { name: 'all', value: 'all' },
      { name: 'page', value: 'page' },
    ],
    fields: '{...ListCampaignsResponseFields}',
    fragments: [Fragments.listCampaignsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_CHANNEL get channel data of the campaign GraphQL schema
export const READ_CHANNEL = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getChannel',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...ChannelFields}',
    fragments: [Fragments.channel],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_CHANNEL list channels of the campaign GraphQL schema
export const LIST_CHANNEL = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listChannels',
    args: [
      { name: 'all', type: 'Boolean' },
      { name: 'campaign_id', type: 'String' },
      { name: 'filters', type: '[Filter!]' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'all', value: 'all' },
      { name: 'campaign_id', value: 'campaign_id' },
      { name: 'filters', value: 'filters' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListChannelsResponseFields}',
    fragments: [Fragments.listChannelsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_PAGES list facebook pages from facebook GRAPHQL
export const LIST_PAGES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listFacebookPages',
    args: '',
    argsMap: '',
    fields: '{...ListFacebookPagesResponseFields}',
    fragments: [Fragments.listFacebookPagesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ_FACEBOOK_SUBSCRIPTION get facebook user of the campaign GraphQL schema
export const READ_FACEBOOK_SUBSCRIPTION = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getFacebookSubscription',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...FacebookAccountSubscriptionFields}',
    fragments: [Fragments.facebookAccountSubscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const FETCH_LIST_FACEBOOK_ACCOUNTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listFBAccountRecords',
    args: [
      { name: 'fb_pages_string', type: 'Boolean', required: true },
      { name: 'sorts', type: '[Sort!]' },
    ],
    argsMap: [
      { name: 'fb_pages_string', value: 'fb_pages_string' },
      { name: 'sorts', value: 'sorts' },
    ],
    fields: '{...ListFBAccountRecordsResponseFields}',
    fragments: [Fragments.listFBAccountRecordsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_FACEBOOK_CAMPAIGNS list facebook campaigns GraphQL schema
export const LIST_FACEBOOK_CAMPAIGNS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listFacebookCampaigns',
    args: '',
    argsMap: '',
    fields: '{...ListFacebookCampaignsResponseFields}',
    fragments: [Fragments.listFacebookCampaignsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_PROJECTS list facebook campaigns GraphQL schema
export const LIST_PROJECTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listProjects',
    args: [],
    argsMap: [],
    fields: '{...ListProjectsResponseFields}',
    fragments: [Fragments.listProjectsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SEND_TEST_BLAST send test for sms/email blast GraphQL schema
export const SEND_TEST_BLAST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'sendTestBlast',
    args: [
      { name: 'mobile', type: 'String' },
      { name: 'emails', type: '[String]' },
      { name: 'sender_email', type: 'String' },
      { name: 'sender_name', type: 'String' },
      { name: 'email_preview', type: 'String' },
      { name: 'subject', type: 'String' },
      { name: 'content', type: 'String', required: true },
      { name: 'channel_id', type: 'String' },
      { name: 'test_blast_count', type: 'Int' },
    ],
    argsMap: [
      { name: 'mobile', value: 'mobile' },
      { name: 'emails', value: 'emails' },
      { name: 'sender_email', value: 'sender_email' },
      { name: 'sender_name', value: 'sender_name' },
      { name: 'email_preview', value: 'email_preview' },
      { name: 'subject', value: 'subject' },
      { name: 'content', value: 'content' },
      { name: 'channel_id', value: 'channel_id' },
      { name: 'test_blast_count', value: 'test_blast_count' },
    ],
    fields: '{...SendTestBlastResponseFields}',
    fragments: [Fragments.sendTestBlastResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// PREVIEW_BLAST preview sms GraphQL schema
export const PREVIEW_BLAST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'previewBlast',
    args: [
      { name: 'content', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'content', value: 'content' },
    ],
    fields: '{...PreviewBlastResponseFields}',
    fragments: [Fragments.previewBlastResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

export const LIST_EMAIL_VERIFICATIONS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listEmailVerifications',
    args: [],
    argsMap: [],
    fields: '{...ListEmailVerificationsResponseFields}',
    fragments: [Fragments.listEmailVerificationsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};


// LIST_CHANNEL list channels of the campaign GraphQL schema
export const LIST_BOUNCED_RECIPIENT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listBouncedRecipient',
    args: [
      { name: 'channel_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'channel_id', value: 'channel_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListBouncedRecipientResponseFields}',
    fragments: [Fragments.listBouncedRecipientResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SYNC_CAMPAIGN_SUMMARY fetch the latest insights count for the campaign GraphQL schema
export const SYNC_CAMPAIGN_SUMMARY = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'syncCampaignSummary',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'id', value: 'id' },
    ],
    fields: '{...CampaignFields}',
    fragments: [Fragments.campaign],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_FACEBOOK_ACTIVITIES list facebook subscription' activities GraphQL schema.
export const LIST_FACEBOOK_ACTIVITIES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listActivities',
    args: [
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListActivitiesResponseFields}',
    fragments: [Fragments.listActivitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
