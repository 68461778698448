import { CONSTRUCTOR } from '..';

export const campaign = CONSTRUCTOR.FRAGMENT({
  name: 'Campaign',
  fields: `{
    id
    name
    description
    project_ids
    company_id
    company_branch_id
    channel_count
    form_count
    submission_count
    qualified_lead_count
    unqualified_lead_count
    deleted_lead_count
    duplicated_count
    opportunity_count
    booked_opportunity_count
    won_opportunity_count
    reach
    total_budget
    total_spent
    starting_at
    ending_at
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
});

export const project = CONSTRUCTOR.FRAGMENT({
  name: 'Project',
  fields: `{
    id
    name
    company_id
    status_id
  }`,
});

export const listCampaignsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCampaignsResponse',
  fields: `{
    items {...CampaignFields}
    pit_id
    search_afters
    count
  }`,
  fragments: [campaign],
});

export const audienceGroupInput = CONSTRUCTOR.FRAGMENT({
  name: 'AudienceGroupInput',
  fields: `{
    id
    name
    count
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

export const channel = CONSTRUCTOR.FRAGMENT({
  name: 'Channel',
  fields: `{
    id
    company_id
    fb_campaign_id
    campaign_id
    name
    channel_type
    page_id
    url
    starting_at
    ending_at
    status
    description
    location
    reach
    engagement
    view_count
    unique_view_count
    submission_count
    qualified_lead_count
    unqualified_lead_count
    deleted_lead_count
    duplicated_count
    opportunity_count
    booked_opportunity_count
    won_opportunity_count
    calls_count
    attendees
    total_budget
    total_spent
    accept_sub
    srb
    change_7d
    budget_amount
    budget_currency_code
    cost_amount
    cost_currency_code
    message
    audience_groups {
      id
      name
      count
      created_at
      created_by
      updated_at
      updated_by
    }
    count
    scheduled_count
    sent_count
    clicked_count
    failed_count
    s3_key
    paused_at
    scheduled_at
    started_at
    delivered_at
    failed_at
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
    sender_name
    sender_email
    subject
    email_preview
    email_design
    email_content
    email_design_s3_key
    email_content_s3_key
    bounced_count
    opened_count
    used_email_blast_count
    form_id
    forwarding_email
    error_reason
    development_id
    listing_portal_type
    project_name
    test_blast_count
    unreachable_email_count
    adsource_id
    printed_media_type
    allow_tracking
    iproperty_api_key
    propertyguru_api_key
    listing_id
    company_branch_id
    invalid_submission_count
  }`,
});

export const listChannelsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListChannelsResponse',
  fields: `{
    items {...ChannelFields}
    count
  }`,
  fragments: [channel],
});

export const facebookAccountSubscription = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookAccountSubscription',
  fields: `{
    id
    company_id
    resync_status
    login_type
    ad_account_ids
    fb_user_info {
      id
      username
      is_valid
      data_access_expires_at
    }    
    fb_pages_info_string
    created_at
    created_by
    updated_at
    updated_by
    deleted_at
    deleted_by
  }`,
});

export const resyncFacebookAdset = CONSTRUCTOR.FRAGMENT({
  name: 'resyncFacebookAdsetResponse',
  fields: `{
    body
  }`,
});

export const facebookResource = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookResource',
  fields: `{
    id
    name
    fb_user_token_id
  }`,
});

export const facebookCampaignGroup = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookCampaignGroup',
  fields: `{
  ad_account_id
  account_username
  campaigns {...FacebookResourceFields}
  }`,
  fragments: [facebookResource],
});

export const listFacebookCampaignsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListFacebookCampaignsResponse',
  fields: `{
    fb_campaigns {...FacebookCampaignGroupFields}
  }`,
  fragments: [facebookCampaignGroup],
});

export const facebookPage = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookPage',
  fields: `{
    id
    name
  }`,
});

export const facebookPageGroup = CONSTRUCTOR.FRAGMENT({
  name: 'FacebookPageGroup',
  fields: `{
  fb_user_id
  fb_user_name
  pages {...FacebookPageFields}
  }`,
  fragments: [facebookPage],
});

export const listFacebookPagesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListFacebookPagesResponse',
  fields: `{
    fb_pages {...FacebookPageGroupFields}
    fb_pages {...FacebookPageGroupFields}
  }`,
  fragments: [facebookPageGroup],
});

export const listFBAccountRecordsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListFBAccountRecordsResponse',
  fields: `{
    fb_accounts {...FacebookAccountSubscriptionFields}
  }`,
  fragments: [facebookAccountSubscription],
});

export const listProjectsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListProjectsResponse',
  fields: `{
    items {...ProjectFields}
  }`,
  fragments: [project],
});

export const condition = CONSTRUCTOR.FRAGMENT({
  name: 'Condition',
  fields: `{
    field
    operator
    value
  }`,
});

export const sendTestBlastResponse = CONSTRUCTOR.FRAGMENT({
  name: 'SendTestBlastResponse',
  fields: `{
    nil
  }`,
});

export const previewBlastResponse = CONSTRUCTOR.FRAGMENT({
  name: 'PreviewBlastResponse',
  fields: `{
    content
  }`,
});

export const dnsRecord = CONSTRUCTOR.FRAGMENT({
  name: 'DNSRecord',
  fields: `{
    type
    name
    value
  }`,
});

export const emailVerification = CONSTRUCTOR.FRAGMENT({
  name: 'EmailVerification',
  fields: `{
    id
    company_id
    email
    domain
    dns_records {...DNSRecordFields}
    type
    status
    created_at
    created_by
    updated_at
    updated_by
  }`,
  fragments: [dnsRecord],
});

export const listEmailVerificationsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListEmailVerificationsResponse',
  fields: `{
    items {...EmailVerificationFields}
  }`,
  fragments: [emailVerification],
});

export const bounceRecipientList = CONSTRUCTOR.FRAGMENT({
  name: 'BounceRecipientList',
  fields: `{
    bounced_reason
    bounced_recipient
    name
  }`,
});

export const listBouncedRecipientResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListBouncedRecipientResponse',
  fields: `{
    items {...BounceRecipientListFields}
    count
  }`,
  fragments: [bounceRecipientList],
});

// listActivitiesResponse fields fragment for fb subscription activity log.
export const listActivitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListActivitiesResponse',
  fields: `{
    items
    count
  }`,
});
