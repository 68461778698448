import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import { isEmpty } from 'lodash';
import { AuthContext } from '../Auth';
import PageContext, { initialState } from './context';
import { SnackbarContext } from '../Snackbar';
import {
  LEAD_FIELD,
  CAMPAIGN,
  CONTACT_FIELD,
  CALLS,
  OPPORTUNITY_FIELD,
} from '../../graphql';
import { enhancedQuery, urlB64ToUint8Array } from '../../utils';
import {
  clientLead,
  clientCampaign,
  clientContact,
  clientCalls,
  clientOpportunity,
} from '../..';
import config from '../../config';
import { storageAvailable } from '../../utils/storage';
import { getCancellationReasons } from '../../data/mhub_api';

export default function PageProvider(props) {
  const { children } = props;
  // LMS-2843
  const authContext = useContext(AuthContext);
  const { apps, user } = authContext.state;

  const snackbarContext = useContext(SnackbarContext);
  const { setOpenSnackbar } = snackbarContext.actions;
  const [retryCount, setRetryCount] = useState(0);
  const [currentInterval, setCurrentInterval] = useState(15000);
  const maxInterval = 300000;
  const intervalIdRef = useRef(null);
  const isFetchingRef = useRef(false);


  const [context, setContext] = useState(initialState);
  const { fbResyncStatus, fbResyncID } = context;
  const [fieldSectionsOrder] = useState([
    'basic',
    'sales',
    'work',
    'emergency_contact',
    'custom',
  ]);
  const [basicFieldsOrder] = useState([
    'title',
    'name',
    'mobile',
    'preferred_name',
    'email',
    'date_of_birth',
    'nric',
    'passport',
    'tin',
    'nationality_country_code',
    'gender',
    'race',
    'marital_status',
    'bumi',
    'address_1',
    'address_2',
    'country_code',
    'state',
    'city',
    'postcode',
  ]);

  // Loading overlay whole web
  const setAppLoading = useCallback((state) => {
    setContext((prev) => ({ ...prev, appLoading: state }));
  }, []);

  const setWhatsNewModal = (state) => {
    const { app } = config;
    setContext({
      ...context,
      showWhatsNewModal: state,
      // Whenever user open whats new modal = see latest release note
      lastReleaseNoteVersionSeen: app.version,
    });

    if (storageAvailable('localStorage')) {
      if (!(localStorage.getItem('lastReleaseNoteVersionSeen')
      && localStorage.getItem('lastReleaseNoteVersionSeen') === app.version)) {
        localStorage.setItem('lastReleaseNoteVersionSeen', app.version);
      }
    }
  };

  // Add custom secondary nav
  const setCustomNavSection = useCallback((state) => {
    setContext((prev) => ({ ...prev, navigations: state }));
  }, []);

  // Pass in indexedDB
  const setIndexedDB = useCallback((state) => {
    setContext((prev) => ({ ...prev, indexedDB: state }));
  }, []);

  // Store indexedDB infos to call it
  const setIndexeDBInfo = useCallback((state) => {
    setContext((prev) => ({ ...prev, indexedDBInfo: state }));
  }, []);

  // Store saved filters
  const setSavedFields = useCallback((state) => {
    setContext((prev) => ({ ...prev, savedFields: state }));
  }, []);

  const getSavedFields = useCallback(async (db, storeName) => {
    const list = [];
    if (db) {
      const keys = await db.getAllKeysFromIndex(storeName, 'name') || [];
      const items = await db.getAllFromIndex(storeName, 'name') || [];

      items.map((item, idx) => list.push({ key: keys[idx], ...item }));
    }
    return list;
  }, []);

  const setIsWindowActive = useCallback((state) => {
    setContext((prev) => ({ ...prev, isWindowActive: state }));
  }, []);

  const queryFacebookSubscription = async (variables) => (
    enhancedQuery(clientCampaign, CAMPAIGN.READ_FACEBOOK_SUBSCRIPTION(), {
      options: {
        fetchPolicy: 'no-cache',
        variables,
      },
      defaultData: {
        getFacebookSubscription: {},
      },
      selector: (d) => d.getFacebookSubscription,
    })
  );

  const FBResyncResp = {
    ready: 'READY',
    failed: 'FAILED',
    processing: 'PROCESSING',
  };

  const setFBResyncStatus = useCallback((state) => {
    setContext((prev) => ({ ...prev, fbResyncStatus: state }));
  }, []);

  const setFBResyncID = useCallback((state) => {
    setContext((prev) => ({ ...prev, fbResyncID: state }));
  }, []);

  // Refetch facebook subcription record every 15s if The resync is still processing
  useEffect(() => {
    const fetchData = async () => {
      if (isFetchingRef.current) { // prevent The refetch process doesn't overlap
        return;
      }
      isFetchingRef.current = true;

      try {
        const res = await queryFacebookSubscription({
          id: fbResyncID,
        });
        if (res && !res.errors && res.resync_status) {
          const status = res.resync_status;
          if (status === 'READY') {
            setFBResyncStatus(FBResyncResp.ready);
            setOpenSnackbar({
              variant: 'success',
              message: 'Success updating facebook adset records',
            });
            clearInterval(intervalIdRef.current);
            setRetryCount(0);
            setCurrentInterval(15000);
          }
          if (status === 'FAILED') {
            setFBResyncStatus(FBResyncResp.failed);
            setOpenSnackbar({
              variant: 'error',
              message: 'Error occured when updating facebook adset records',
            });
          }
          clearInterval(intervalIdRef.current);
          setRetryCount(0);
          setCurrentInterval(15000);
        } else {
          throw res.errors;
        }
      } catch (error) {
        setFBResyncStatus(FBResyncResp.failed);
        setOpenSnackbar({
          variant: 'error',
          message: 'An error occurred: Unable to update Facebook ad set records due to a failure in the operation status.',
        });
      } finally {
        isFetchingRef.current = false; // Reset fetching flag to false
      }
    };

    const startInterval = () => {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = setInterval(() => {
        fetchData();
        setRetryCount((prevCount) => prevCount + 1);
        setCurrentInterval((prevInterval) => {
          const nextInterval = prevInterval * 2;
          return nextInterval <= maxInterval ? nextInterval : maxInterval;
        });
      }, currentInterval);
    };

    if (fbResyncStatus === FBResyncResp.processing) {
      startInterval();
    } else {
      clearInterval(intervalIdRef.current);
    }

    return () => {
      clearInterval(intervalIdRef.current); // Cleanup on unmount
    };
  }, [
    fbResyncID,
    retryCount,
    FBResyncResp,
    fbResyncStatus,
    currentInterval,
    setOpenSnackbar,
    setFBResyncStatus,
  ]);

  const queryAvanserAuthentication = async () => (
    enhancedQuery(clientCalls, CALLS.GET_ACCOUNT(), {
      options: {
        fetchPolicy: 'no-cache',
        variables: {},
      },
      defaultData: {
        getAccount: {},
      },
      selector: (d) => d.getAccount,
    })
  );

  const setAvanserAuthentication = useCallback(async (isFromParent, data) => {
    let account = {};
    if (isFromParent) {
      account = data;
    } else {
      account = await queryAvanserAuthentication();
    }
    setContext((prevState) => ({
      ...prevState,
      avanserAccount: {
        ...account,
      },
    }));
  }, []);

  // Set avanser authentication context on load
  useEffect(() => {
    async function getAvanserAccount() {
      await setAvanserAuthentication();
    }
    getAvanserAccount();
  }, [setAvanserAuthentication]);

  // Set lastReleaseNoteVersionSeen context from local storage on load
  useEffect(() => {
    if (storageAvailable('localStorage')
      && localStorage.getItem('lastReleaseNoteVersionSeen')) {
      setContext((prevState) => ({
        ...prevState,
        lastReleaseNoteVersionSeen: localStorage.getItem('lastReleaseNoteVersionSeen'),
      }));
    }
  }, []);

  const queryLeadFields = async () => (
    enhancedQuery(clientLead, LEAD_FIELD.READ(), {
      options: {
        fetchPolicy: 'no-cache',
      },
      defaultData: {
        getLeadFieldSettings: {
          body: JSON.stringify({
            basic: { attributes: [] },
            custom: { attributes: [] },
          }),
        },
      },
      selector: (d) => d.getLeadFieldSettings,
    })
  );

  const getSortedSections = useCallback((settings) => {
    if (settings) {
      const filtered = fieldSectionsOrder.filter(
        (i) => Object.keys(settings).includes(i),
      );
      const sorted = filtered.reduce((r, k) => Object.assign(
        r, { [k]: settings[k] },
      ), {});
      return sorted;
    }
    return {};
  }, [fieldSectionsOrder]);

  const getSortedFields = useCallback((fields, type) => {
    if (fields[type] && fields[type].attributes) {
      // Remove those in order that doesnt exists in settings
      const filtered = basicFieldsOrder.filter(
        (i) => Object.keys(fields[type].attributes).includes(i),
      );

      // Push in field that is not in the order
      Object.keys(fields[type].attributes).forEach((field) => {
        if (!filtered.includes(field)) {
          filtered.push(field);
        }
      });

      const sorted = filtered.reduce((r, k) => Object.assign(
        r, { [k]: fields[type].attributes[k] },
      ), {});

      return sorted;
    }
    return {};
  }, [basicFieldsOrder]);

  const setLeadFieldSettings = useCallback(async () => {
    setContext((prevState) => ({
      ...prevState,
      pageContextLoaders: {
        ...prevState.pageContextLoaders,
        leadFieldSettings: true,
      },
    }));

    let fields = await queryLeadFields();
    fields = JSON.parse(fields.body);

    if (!fields) {
      setTimeout(() => {
        setLeadFieldSettings();
      }, 1000);
    } else {
      const sortedAttributes = {
        leadFieldSettings: {
          ...fields,
          basic: {
            attributes: {
              ...getSortedFields(fields, 'basic'),
            },
          },
          emergency_contact: {
            attributes: {
              ...getSortedFields(fields, 'emergency_contact'),
            },
          },
        },
      };
      setContext((prevState) => ({
        ...prevState,
        leadFieldSettings: {
          ...getSortedSections(sortedAttributes.leadFieldSettings),
        },
        pageContextLoaders: {
          ...prevState.pageContextLoaders,
          leadFieldSettings: false,
        },
      }));
    }
  }, [getSortedFields, getSortedSections]);

  // Set lead fields context on load
  useEffect(() => {
    async function getFields() {
      await setLeadFieldSettings();
    }
    getFields();
  }, [
    setLeadFieldSettings,
    queryLeadFields.data,
  ]);

  const queryContactFields = async () => (
    enhancedQuery(clientContact, CONTACT_FIELD.READ(), {
      options: {
        fetchPolicy: 'no-cache',
      },
      defaultData: {
        getContactFieldSettings: {
          body: JSON.stringify({
            basic: { attributes: [] },
            custom: { attributes: [] },
          }),
        },
      },
      selector: (d) => d.getContactFieldSettings,
    })
  );

  const setContactFieldSettings = useCallback(async () => {
    setContext((prevState) => ({
      ...prevState,
      pageContextLoaders: {
        ...prevState.pageContextLoaders,
        contactFieldSettings: true,
      },
    }));

    let fields = await queryContactFields();
    fields = JSON.parse(fields.body);

    if (!fields) {
      setTimeout(() => {
        setContactFieldSettings();
      }, 1000);
    } else {
      const sortedAttributes = {
        contactFieldSettings: {
          ...fields,
          basic: {
            attributes: {
              ...getSortedFields(fields, 'basic'),
            },
          },
          sales: {
            attributes: {
              ...getSortedFields(fields, 'sales'),
            },
          },
          emergency_contact: {
            attributes: {
              ...getSortedFields(fields, 'emergency_contact'),
            },
          },
        },
      };
      setContext((prevState) => ({
        ...prevState,
        contactFieldSettings: {
          ...getSortedSections(sortedAttributes.contactFieldSettings),
        },
        pageContextLoaders: {
          ...prevState.pageContextLoaders,
          contactFieldSettings: false,
        },
      }));
    }
  }, [getSortedFields, getSortedSections]);

  // Set contact fields context on load
  useEffect(() => {
    async function getFields() {
      await setContactFieldSettings();
    }
    getFields();
  }, [
    setContactFieldSettings,
    queryContactFields.data,
  ]);


  const queryOpportunityFields = async () => (
    enhancedQuery(clientOpportunity, OPPORTUNITY_FIELD.READ(), {
      options: {
        fetchPolicy: 'no-cache',
      },
      defaultData: {
        getOpportunityFieldSettings: {
          body: JSON.stringify({
            basic: { attributes: [] },
          }),
        },
      },
      selector: (d) => d.getOpportunityFieldSettings,
    })
  );

  const setOpportunityFieldSettings = useCallback(async () => {
    let fields = await queryOpportunityFields();
    fields = JSON.parse(fields.body);

    if (!fields) {
      setTimeout(() => {
        setOpportunityFieldSettings();
      }, 1000);
    } else {
      setContext((prevState) => ({
        ...prevState,
        opportunityFieldSettings: {
          ...fields,
        },
      }));
    }
  }, []);

  // Set opportunity fields context on load
  useEffect(() => {
    async function getFields() {
      await setOpportunityFieldSettings();
    }
    getFields();
  }, [
    setOpportunityFieldSettings,
    queryOpportunityFields.data,
  ]);

  const setCancellationReasons = useCallback(async () => {
    if (apps && apps.showroom && apps.showroom.apiURL) {
      const reasons = await getCancellationReasons(apps.showroom.apiURL);

      if (reasons && !isEmpty(reasons)) {
        setContext((prevState) => ({
          ...prevState,
          cancellationReasonOptions: reasons.map((reason) => ({
            label: reason.name,
            value: reason.id,
          })),
        }));
      }
    }
  }, [apps]);

  // Set booking cancellation reasons context on load
  useEffect(() => {
    async function getCancellationReasonSettings() {
      await setCancellationReasons();
    }
    getCancellationReasonSettings();
  }, [setCancellationReasons]);

  const queryListCampaigns = async (limit) => (
    enhancedQuery(clientCampaign, CAMPAIGN.LIST({
      fields: `{
        count
        items {
          id
          name
        }
      }`,
      fragments: [],
    }), {
      options: {
        fetchPolicy: 'no-cache',
        variables: {
          sorts: [{ field: 'name', order: 'asc' }],
          limit,
        },
      },
      defaultData: {
        listCampaigns: {
          items: [],
          count: 0,
        },
      },
    })
  );

  const setListCampaigns = useCallback(async (limit) => {
    const campaigns = await queryListCampaigns(limit);
    if (!campaigns) {
      setTimeout(() => {
        setListCampaigns();
      }, 1000);
      return;
    }
    const { count } = campaigns.listCampaigns;
    const items = campaigns.listCampaigns.items || [];

    // To get full campaign list
    if (count > limit) {
      setListCampaigns(count);
      return;
    }

    // Get list of campaigns for drop down options
    const options = items.map((campaign) => ({ label: campaign.name, value: campaign.id }));
    setContext((prevState) => ({
      ...prevState,
      campaignOptions: options,
    }));
  }, []);

  useEffect(() => {
    async function getCampaigns(limit = 50) {
      await setListCampaigns(limit);
    }
    getCampaigns();
  }, [setListCampaigns]);

  // Get all cam id & name only including deleted/ no access
  const queryAllListCampaigns = async () => (
    enhancedQuery(clientCampaign, CAMPAIGN.LIST(), {
      options: {
        fetchPolicy: 'no-cache',
        variables: {
          all: true,
        },
      },
      defaultData: {
        listCampaigns: {
          items: [],
          count: 0,
        },
      },
    })
  );

  const setAllListCampaigns = useCallback(async () => {
    const campaigns = await queryAllListCampaigns();
    if (!campaigns) {
      setTimeout(() => {
        setAllListCampaigns();
      }, 1000);
      return;
    }

    const items = campaigns.listCampaigns.items || [];

    // Get list of campaigns in { cam_id: { ...cam info }, ... } format
    const list = items.length > 0
      ? items.reduce((obj, item) => ({
        ...obj,
        [item.id]: {
          name: item.name || item.id,
          project_ids: item.project_ids || [],
        },
      }), {})
      : {};
    setContext((prevState) => ({
      ...prevState,
      campaigns: list,
    }));
  }, []);

  useEffect(() => {
    async function getAllCampaigns() {
      await setAllListCampaigns();
    }
    getAllCampaigns();
  }, [setAllListCampaigns]);

  const queryListProjects = async () => (
    enhancedQuery(clientCampaign, CAMPAIGN.LIST_PROJECTS(), {
      defaultData: {
        listProjects: {
          items: [],
          count: 0,
        },
      },
    })
  );

  const setListProjects = useCallback(async () => {
    const projects = await queryListProjects();
    if (!projects) {
      setTimeout(() => {
        setListProjects();
      }, 1000);
      return;
    }
    const items = projects.listProjects.items || [];

    const list = items.length > 0
      ? items.reduce((obj, item) => ({ ...obj, [item.id]: { name: item.name } }), {}) : {};
    // Get list of projects for drop down options
    const options = items.map((project) => ({
      label: project.name,
      value: project.id,
      statusID: project.status_id,
    }));
    setContext((prevState) => ({
      ...prevState,
      projects: list,
      projectOptions: options,
    }));
  }, []);

  useEffect(() => {
    // Get all projects regardless restriction to get project info
    async function getProjects() {
      await setListProjects();
    }
    getProjects();
  }, [setListProjects]);

  // LMS-2843
  // const setListOpportunityChatGroups = useCallback(async () => {
  //   const companyID = user.company.id;
  //   let list = {};
  //   if (companyID) {
  //     try {
  //       const response = await API.get('CHAT', `/groups/coid:${companyID}`);
  //       if (response && response.length > 0) {
  //         list = response.reduce((obj, item, idx) => {
  //           if (item.id && item.name) {
  //             const type = item.name.split(':')[0];
  //             if (type && type === 'opp') {
  //               const opportunityID = item.name.split('opp:').pop().split('#pcust')[0];
  //               return {
  //                 ...obj,
  //                 [opportunityID]: {
  //                   chat_group_id: item.id,
  //                   counts: idx,
  //                 },
  //               };
  //             }
  //           }
  //           return obj;
  //         }, {});
  //       } else {
  //         list = {};
  //       }
  //     } catch (error) {
  //       Bugsnag.notify(`Failed to retrieve chat groups: ${error.message}`);
  //       list = {};
  //     }
  //     setContext((prevState) => ({
  //       ...prevState,
  //       opportunityChatGroups: list,
  //     }));
  //   }
  // }, [user]);

  // LMS-2843
  // useEffect(() => {
  //   async function getOpportunityChatGroups() {
  //     await setListOpportunityChatGroups();
  //   }
  //   getOpportunityChatGroups();
  // }, [setListOpportunityChatGroups]);

  const requestNotificationPermission = async () => (
    new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
  );

  const sendSubscriptionToServer = useCallback(async (subscription) => {
    if (user.id) {
      if (subscription) {
        try {
          await API.post(
            'LEAD',
            '/notifications/webpush',
            {
              headers: {
                'x-user-id': user.id,
              },
              body: subscription,
            },
          );
        } catch (err) {
          Bugsnag.notify(`Failed to send subscription to server: ${err}`);
        }
      } else {
        console.warn('[Subscription] Not subscribed to web push or permission denied');
      }
    }
  }, [user.id]);

  const subscribePushManager = useCallback(async (registration) => {
    try {
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(config.app.subscriptionPublicVapidKey),
      };
      const subscription = await registration.pushManager.subscribe(options);
      await sendSubscriptionToServer(subscription);
    } catch (err) {
      Bugsnag.notify(`Failed to subscribe push manager: ${err}`);
    }
  }, [sendSubscriptionToServer]);

  const registerServiceWorker = useCallback(() => {
    if ('serviceWorker' in navigator
    && 'PushManager' in window
    && 'Notification' in window) {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`)
        .then((reg) => {
          // If is already activated
          if (reg.active && reg.active.state === 'activated') {
            // Subscription might change during their lifetime
            // So need to always update to sync with server
            reg.pushManager.getSubscription()
              .then((sub) => {
                if (sub !== null) {
                  sendSubscriptionToServer(sub);
                } else {
                  requestNotificationPermission()
                    .then(async (resp) => {
                      if (resp === 'granted') {
                        await subscribePushManager(reg);
                      }
                    });
                }
              });
            setContext((prev) => ({
              ...prev,
              swIsRegistered: true,
            }));
          } else {
            const registration = reg;
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                installingWorker.onstatechange = () => {
                  if (registration.active) {
                    registration.active.onstatechange = () => {
                      if (registration.active.state && registration.active.state === 'activated') {
                        setContext((prev) => ({
                          ...prev,
                          swIsRegistered: true,
                        }));
                        requestNotificationPermission()
                          .then(async (resp) => {
                            if (resp === 'granted') {
                              await subscribePushManager(registration);
                            }
                          });
                      }
                    };
                  }
                };
              }
            };
          }
        })
        .catch((err) => {
          console.error('[Service worker] Unable to register service worker.', err);
        });
    }
  }, [
    subscribePushManager,
    sendSubscriptionToServer,
  ]);

  useEffect(() => {
    registerServiceWorker();
  }, [registerServiceWorker]);

  const value = {
    state: context,
    actions: {
      setAppLoading,
      setWhatsNewModal,
      setAvanserAuthentication,
      setLeadFieldSettings,
      setContactFieldSettings,
      setOpportunityFieldSettings,
      setCancellationReasons,
      setListCampaigns,
      setAllListCampaigns,
      // LMS-2843
      // setListOpportunityChatGroups,
      setCustomNavSection,
      setIndexedDB,
      setIndexeDBInfo,
      setIsWindowActive,
      setFBResyncStatus,
      setFBResyncID,
      setSavedFields,
      getSavedFields,
    },
  };

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
}

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
